import React from 'react'
import { graphql } from 'gatsby'
import { PodcastPage } from '@/components'

const Podcast = ({ ...props }) => <PodcastPage {...props} />

export default Podcast

export const PodcastPageQuery = graphql`
  query PodcastPageQuery($lang: String!) {
    allPrismicPodcastepisode(
      sort: { fields: data___sort_date, order: DESC }
      filter: { lang: { eq: "en-us" } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            episode
            summary {
              html
            }
            serie {
              uid
            }
            disable_link
            sort_date
            meta_release_date {
              text
            }
          }
        }
      }
    }
    prismicPodcastpage(lang: { eq: $lang }) {
      data {
        subhead {
          text
        }
        title {
          text
        }
        summary {
          html
          text
        }
        social_image {
          url
        }
        series_heading {
          text
        }
        episodes_heading {
          text
        }
        sidebar_heading {
          text
        }
        more_details {
          text
        }
      }
    }
    allPrismicPodcastserie(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            image {
              url
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
